import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import PageBlock from "../components/Layout/PageBlock";
import PageLayout from "../components/Layout/PageLayout";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

import { ArrowRight } from "react-feather";
import { PageBlockTitle, CTALink, PageBlockNextPageDivider, primaryColour } from "../styles/component-stylings";
import { device_query } from "../helpers/device_breakpoints";
import { mobileAndTabletCheck } from "../helpers/utils";
import ModalComponent from "../components/ModalComponent";

const PAGE_TITLE = "The work";

const WorkBlockContainer = styled.div`
    width: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

const IconContainer = styled.div`
    min-height: 200px;
    flex: 1 0 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 150ms ease-in;

    &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    }

    @media ${device_query.tablet} {
        flex: 1 0 calc(100% / 4);
    }
`;

const IconContainerS = styled(IconContainer)`
    &:hover {
        cursor: pointer;
    }
`;

const IconImgContainer = {
    height: "200px",
};

const WorkTitle = styled.p`
    font-weight: 500;
    text-align: center;
`;

const Box = styled.div`
    width: fit-content;
    height: fit-content;
    position: fixed;
    display: block;
    bottom: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background-color: ${primaryColour};
    border-radius: 1rem;
    font-size: 0.75rem;
    color: white;
    opacity: ${(props) => (props.$show ? 1 : 0)};
    transition: all 150ms ease-in;
`;

const TheWorkPage = ({ pageContext }) => {
    const [showToast, setShowToast] = useState(false);
    const [showModal, setShowModal] = useState(false);

    let toastInterval = useRef();

    useEffect(() => {
        if (showToast) {
            clearInterval(toastInterval.current);
            toastInterval.current = setInterval(() => {
                setShowToast(false);
            }, 500);
        }
    }, [showToast]);

    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='Showcasing different the work we do at Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <p>We assist the AFP through a variety of support functions for operational tasks.</p>

                <WorkBlockContainer>
                    <IconContainer>
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='cover'
                            src='../images/software.jpg'
                            alt='Close-up of a computer monitor with code'
                            title='Close-up of a computer monitor with code'
                        />
                        <WorkTitle>Software Development</WorkTitle>
                    </IconContainer>
                    <IconContainerS
                        onClick={() => {
                            mobileAndTabletCheck() ? setShowModal(true) : setShowToast((prevShow) => !prevShow);
                        }}
                    >
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='cover'
                            src='../images/ghidra.jpg'
                            title='Check the Web Developer Tool console'
                            alt='Reverse Engineering'
                        />
                        <WorkTitle>Reverse Engineering</WorkTitle>
                    </IconContainerS>
                    <IconContainer>
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='cover'
                            src='../images/servers.jpg'
                            alt='Server rack with cables connected'
                            title='Server rack with cables connected'
                        />
                        <WorkTitle>System Administration</WorkTitle>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='cover'
                            src='../images/electronics.jpg'
                            alt='Close-up of a PCB'
                            title='Close-up of a PCB'
                        />
                        <WorkTitle>Custom Surveillance Devices</WorkTitle>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='cover'
                            src='../images/analytics.jpg'
                            alt='Table screen with analytic graphs'
                            title='Table screen with analytic graphs'
                        />
                        <WorkTitle>Data Enrichment and Analysis</WorkTitle>
                    </IconContainer>
                </WorkBlockContainer>

                <p>This work may include:</p>
                <ul>
                    <li>Analysis of computers, networks, communications and underlying protocols.</li>
                    <li>Development of purpose-built software applications - web based user interfaces, RESTful APIs, bespoke data conversion tools.</li>
                    <li>
                        Interaction with commercial off-the-shelf software and hardware, developing interfaces and integrations to seamlessly interoperate with
                        corporate systems and networks.
                    </li>
                    <li>
                        Identifying new opportunities (or threats) in emerging technology; undertaking research projects to explore these and how the AFP may
                        respond.
                    </li>
                    <li>
                        Implementing new technology concepts; applying data analysis techniques and development of systems to provide this en-masse to large
                        volumes of collected data.
                    </li>
                    <li>
                        Working with partner Australian Government Agencies, as well as international partner agencies, on research or joint technical
                        capability development projects.
                    </li>
                    <li>
                        Assisting frontline operations staff, providing direct input into and supporting tools used by frontline operation staff, to investigate
                        and combat crimes across a variety of categories such as Counter Terrorism, Organised Crime, Cyber and Online-enabled Crime.
                    </li>
                    <li>Designing custom hardware and software solutions in support of AFP operations.</li>
                </ul>

                <PageBlockTitle>Highlights</PageBlockTitle>
                <p>We are proud of the work we do, though most of it is of a sensitive nature.</p>
                <p>All the work we do has tangible outcomes in supporting completed and ongoing investigations.</p>

                <CTALink to='/the-work/highlights' rel='next'>
                    <span>Learn more recent successes</span>
                    <ArrowRight />
                </CTALink>

                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/careers' rel='next'>
                        <span>Careers</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>

            {/* web console toast */}
            <Box $show={showToast}>
                <p>
                    <strong>
                        <center>Easter Egg #2</center>
                    </strong>
                </p>
                <p>Check the Web Developer Tool console</p>
            </Box>
            {/* modal */}
            <ModalComponent show={showModal} close={() => setShowModal(false)} />
        </PageLayout>
    );
};

export default TheWorkPage;
