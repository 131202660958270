import * as React from "react";
import { Link } from "gatsby";

import { capitaliseFirstWordReturnAsString } from "../../helpers/utils";

const Breadcrumb = ({ pageContext }) => {
    return pageContext?.breadcrumb?.crumbs.map((loc, index, array) => (
        <>
            {index !== array.length - 1 ? (
                <Link key={`${loc.pathname}-link-${index}`} to={loc.pathname}>
                    {capitaliseFirstWordReturnAsString(loc.crumbLabel, "-")}
                </Link>
            ) : (
                <span key={`${loc.pathname}-divider-${index}`}>{capitaliseFirstWordReturnAsString(loc.crumbLabel, "-")}</span>
            )}
            {index !== array.length - 1 && <span key={`${loc.pathname}-divider-${index}`}> / </span>}
        </>
    ));
};

export default Breadcrumb;
