import * as React from "react";
import styled from "styled-components";

import NavigationBlock from "./NavigationBlock";

import { navBarHeight } from "../../styles/component-stylings";
import { device_query, device_size } from "../../helpers/device_breakpoints";

const PageBlock = ({ children, backgroundColour, isFirstBlock, pageContext }) => {
    const Section = styled.section`
        ${isFirstBlock && `margin-top: ${navBarHeight};`}
    `;

    const Container = styled.div`
        display: flex;
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            background: transparent;
            box-shadow: 0 0 4rem 1rem rgba(0, 0, 0, 0.8);
            border-radius: 60%;
            transform: translateZ(0);
        }

        @media ${device_query.laptop} {
            margin: 5rem calc((100% - ${device_size.laptop}) / 2);
        }
    `;

    const ContentContainer = styled.div`
        flex-grow: 1;
        padding: 2rem;
        ${backgroundColour && `background-color: ${backgroundColour};`}
    `;

    const NavigationContainer = styled.aside`
        display: none;

        @media ${device_query.laptop} {
            background-color: white;
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            width: 15rem;
            margin-left: 2rem;
            height: fit-content;
            position: sticky;
            top: ${navBarHeight};
        }
    `;

    return (
        <Section>
            <Container>
                <ContentContainer>{children}</ContentContainer>
                <NavigationContainer>
                    <NavigationBlock pageContext={pageContext} />
                </NavigationContainer>
            </Container>
        </Section>
    );
};

export default PageBlock;
