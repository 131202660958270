import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { darkGrey, primaryColour } from "../../styles/component-stylings";
import { links } from "../../helpers/links";
// import { convertRemToPixels } from "../../helpers/utils";

import { ChevronRight } from "react-feather";

const NavigationStyledLink = styled((props) => <Link {...props} />)`
    display: flex;
    align-items: flex-end;

    font-size: 0.75rem;
    color: ${(props) => (props.$active ? `${primaryColour}` : `${darkGrey}`)};
    padding: 1rem;
    text-decoration: none;
    font-weight: ${(props) => (props.$active ? "500" : "400")};
    border-top: 1px #abbcc4 solid;

    &:hover,
    &:focus {
        text-decoration: underline;
        color: #0066cc;
        background-color: lightblue;
    }
`;

const NavigationHeader = styled.p`
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;
    padding: 1rem;
`;

const NavigationSublinks = styled((props) => <Link {...props} />)`
    display: flex;
    align-items: center;

    font-size: 0.75rem;
    color: ${(props) => (props.$active ? `${primaryColour}` : `${darkGrey}`)};
    padding: 0.5rem 2rem;
    text-decoration: none;
    font-weight: ${(props) => (props.$active ? "500" : "400")};

    &:hover,
    &:focus {
        text-decoration: underline;
        color: #0066cc;
        background-color: lightblue;
    }
`;

const isSameCategory = (url_to_match, page_context) => {
    if (!Boolean(url_to_match)) return false;
    try {
        if (!Boolean(page_context?.breadcrumb?.crumbs[1])) return false;
        if (url_to_match === page_context?.breadcrumb?.crumbs[1].pathname) return true;
    } catch {
        // couldnt find breadcrumb
        return false;
    }
};

const isSameLink = (url_to_match, page_context) => {
    if (!Boolean(url_to_match)) return false;
    try {
        if (!Boolean(page_context?.breadcrumb?.crumbs[2])) return false;
        if (url_to_match === page_context?.breadcrumb?.crumbs[2].pathname) return true;
    } catch {
        // couldnt find breadcrumb
        return false;
    }
};

const NavigationBlock = ({ pageContext }) => {
    return (
        <>
            <NavigationHeader>Navigation</NavigationHeader>
            {links.map((link) => (
                <>
                    <NavigationStyledLink $active={isSameCategory(link.url, pageContext) ? true : undefined} to={link.url} key={`${link.url}-navlink`}>
                        {isSameCategory(link.url, pageContext) && <ChevronRight key={`${link.url}-navlink-chevron`} size='16px' />}
                        <span key={`${link.url}-navlink-text`}>{link.text}</span>
                    </NavigationStyledLink>
                    {/* only generating sublinks for selected category */}
                    {isSameCategory(link.url, pageContext) &&
                        link.sublinks.map((sublink, index) => (
                            <NavigationSublinks
                                $active={isSameLink(sublink.url, pageContext) ? true : undefined}
                                to={sublink.url}
                                key={`${sublink.url}-sublink-${index}`}
                            >
                                {isSameLink(sublink.url, pageContext) && <ChevronRight key={`${sublink.url}-sublink-${index}-chevron`} size='16px' />}
                                <span key={`${sublink.url}-sublink-${index}-text`}>{sublink.text}</span>
                            </NavigationSublinks>
                        ))}
                </>
            ))}
        </>
    );
};

export default NavigationBlock;
